@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip-arrow {
    clip-path: polygon(80% 10%, 0% 10%, 40% 60%);
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.locale-switcher {
  @apply after:clip-arrow relative flex items-center rounded-md bg-slate-200 py-1 pe-2 ps-2 text-slate-800 after:absolute after:end-2 after:top-[10px] after:block after:h-3 after:w-3 after:bg-slate-800 after:content-[''];
}
.locale-switcher select {
  @apply cursor-pointer appearance-none bg-transparent pe-6 text-sm font-light uppercase;
}

@media (max-width: 640px) {
  .container {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.mia-list li:before {
  position: absolute;
  left: 0;
  top: 0.6em;
  width: 5px;
  height: 5px;
  display: block;
  content: "";
  border-radius: 100%;
  background: #0284c7 ;
}

.mia-list li {
  position: relative;
  list-style: none;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -18px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 18px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: white;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}